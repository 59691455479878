import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardData from '../data/CardData';
import Grid from '@material-ui/core/Grid';
import FeatureData from '../data/FeatureData';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3)
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1)
            }
        },
        subtitle: {
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3)
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1)
            }
        },
        dividerText: {
            fontWeight: 'bold',
            marginBottom: theme.spacing(1),
        },
        divider: {
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3)
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1)
            }
        },
        avatar: {
            margin: theme.spacing(1),
            width: theme.spacing(5),
            height: theme.spacing(5),
            borderRadius: 10,
        },
        cardTitle: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            }
        }
    })
);

export interface ITriggerCardProps {
    cardData: CardData,
    primaryTriggers: FeatureData[],
    secondaryTriggers: FeatureData[],
}

export function TriggerCard(props: ITriggerCardProps) {
    const classes = useStyles();
    const { title, description, guideline1, guideline2 } = props.cardData;

    const [currentTrigger, setCurrentTrigger] = React.useState(props.cardData.title)
    const [appExample, setAppExample] = React.useState(props.primaryTriggers[Math.floor(Math.random() * props.primaryTriggers.length)])
    const [otherExample, setOtherExample] = React.useState(props.secondaryTriggers[Math.floor(Math.random() * props.secondaryTriggers.length)])

    const handleCardRefresh = () => {
        setAppExample(props.primaryTriggers[Math.floor(Math.random() * props.primaryTriggers.length)]);
        setOtherExample(props.secondaryTriggers[Math.floor(Math.random() * props.secondaryTriggers.length)])
    };

    if (currentTrigger !== props.cardData.title) {
        setCurrentTrigger(props.cardData.title);
        handleCardRefresh()
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <Hidden xsDown implementation="css">
                    <Typography gutterBottom variant="h5" component="h2" align="center">{title}</Typography>
                </Hidden>

                <Typography className={classes.subtitle} variant="subtitle1" color="textSecondary" component="h3" align="center">{description}</Typography>

                <Typography className={`${classes.divider} ${classes.dividerText}`} variant="subtitle1" color="primary" component="h4" align="center">Consider also to...</Typography>

                <Grid container spacing={1} alignItems='center' justify='center'>
                    <Grid item xs={12} md={5}>
                        <Typography component="p" align="center">
                            {guideline1}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="subtitle1" color="primary" component="p" align="center">or</Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography component="p" align="center">
                            {guideline2}
                        </Typography>
                    </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Typography className={classes.dividerText} variant="subtitle1" color="primary" component="h4" align="center">Examples</Typography>

                <Grid container spacing={1} alignItems='center' justify='center'>
                    <Grid item xs={2} md={1} >
                        <img alt={appExample.appName + " Logo"} src={appExample.getLogoUrl()} className={classes.avatar} />
                    </Grid>
                    <Grid item xs={10} md={4} >
                        <Typography component="p" align="center">
                            {appExample.getExampleDescription()}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Typography variant="subtitle1" color="primary" component="p" align="center">or</Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography component="p" align="center">
                            {otherExample.getExampleDescription()}
                        </Typography>
                    </Grid>
                </Grid>

            </CardContent>
            <CardActions>
                <Button size="medium" color="secondary" onClick={handleCardRefresh} >Refresh examples</Button>
            </CardActions>
        </Card>
    );
}
