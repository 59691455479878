const data = [
    {
      "App": "Spotify",
      "Features": "List previous listened to artists",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "List just the songs saved from the artist",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Shuffle songs in a playlist",
      "Creativity trigger": "Entertaining",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Save favourite artists",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Create daily playlists based on favourite songs and artists",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Create personal playlists based on favourite songs and artists",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Personalize playlists based on listening history",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Create playlists",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Find recommended songs",
      "Creativity trigger": "Entertaining",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Navigate intuitively between views",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Music player",
      "Features": "Scroll through list quickly with A-Z navigation bar",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "Music player",
      "Features": "Move an album to front of recently played albums",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Spotify",
      "Features": "Promptness in answering user questions and problems",
      "Creativity trigger": "Software quality",
      "Secondary": ""
    },
    {
      "App": "Music player",
      "Features": "Add songs to multiple playlists through checkboxes",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "Spotify",
      "Features": "Choose personal songs",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Listen to entire albums",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Always use the application when necessary",
      "Creativity trigger": "Durable",
      "Secondary": ""
    },
    {
      "App": "Music player",
      "Features": "Combine playlists",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Music player",
      "Features": "Add a song to a playlist through the playlist view",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "Music player",
      "Features": "Change the order of songs individually",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Spotify",
      "Features": "Navigate through the app fluidly",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Edit, remove and move around songs within a playlist",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Interact with a beautiful layout",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Music player",
      "Features": "Listen to all saved music",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Music player",
      "Features": "Listen to songs the user saved by a certain artist",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Spotify",
      "Features": "Listen to a large number of songs and albums",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Music player",
      "Features": "See who follows a playlist",
      "Creativity trigger": "Not in app",
      "Secondary": "Entertaining"
    },
    {
      "App": "Music player",
      "Features": "See who follows you",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Music player",
      "Features": "Get informed about followed artists' recent activity",
      "Creativity trigger": "Not in app",
      "Secondary": "Entertaining"
    },
    {
      "App": "Spotify",
      "Features": "Save songs",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Have folders of music",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Spotify",
      "Features": "Listen to free music",
      "Creativity trigger": "Economical",
      "Secondary": ""
    },
    {
      "App": "Music player",
      "Features": "Delete a song from a playlist directly while song is playing",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "Music player",
      "Features": "Resume a playlist at previous point",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "Instagram",
      "Features": "Edit photos through filters and tools",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Image sharing app",
      "Features": "Edit photos with a Bokeh option",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Instagram",
      "Features": "Tag people in posts",
      "Creativity trigger": "Entertaining",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Livestream video",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Add different types of posts",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Direct message others",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Set restrictions for who views your posts",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Add links to your profile",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Add information about yourself to your profile",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Comment on posts",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Delete comments",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Block and report other users",
      "Creativity trigger": "Durable",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Translate posts to other languages",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Save images",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Image sharing app",
      "Features": "Favourite specific people so they show up at the top of your feed",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Image sharing app",
      "Features": "View original sized photos",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Image sharing app",
      "Features": "Search people that viewed your story",
      "Creativity trigger": "Not in app",
      "Secondary": "Entertaining"
    },
    {
      "App": "Image sharing app",
      "Features": "View comments in chronological order",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Image sharing app",
      "Features": "Post videos with a bigger time frame",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Image sharing app",
      "Features": "Turn off read confirmations",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Image sharing app",
      "Features": "Receive a notification of how many people viewed your story",
      "Creativity trigger": "Not in app",
      "Secondary": "Entertaining"
    },
    {
      "App": "Image sharing app",
      "Features": "View posts in chronological order",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Image sharing app",
      "Features": "Delete a single picture from post without deleting entire post",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Image sharing app",
      "Features": "Preview selected pictures from private accounts",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Instagram",
      "Features": "Create posts to share with others",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Follow other users",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Create a story",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Instagram",
      "Features": "Like posts",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Image sharing app",
      "Features": "Change your timeline from suggested order to chronological",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Instagram",
      "Features": "View other people's pictures",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Image sharing app",
      "Features": "Edit posts",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Image sharing app",
      "Features": "Edit picture captions",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Video streaming app",
      "Features": "Setup a teen account that blocks certain shows",
      "Creativity trigger": "Not in app",
      "Secondary": "Durable"
    },
    {
      "App": "Netflix",
      "Features": "Download videos for watching without WiFi",
      "Creativity trigger": "Economical",
      "Secondary": ""
    },
    {
      "App": "Netflix",
      "Features": "User multiple user profiles",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Netflix",
      "Features": "Easily navigate the application",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Video streaming app",
      "Features": "Show alert when a show or movie will be removed soon",
      "Creativity trigger": "Not in app",
      "Secondary": "Entertaining"
    },
    {
      "App": "Netflix",
      "Features": "Get notified when new movies are available",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Netflix",
      "Features": "Setup the application to only show child appropriate content",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Netflix",
      "Features": "Setup the application to only show adult specific content",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Video streaming app",
      "Features": "Rate content with stars",
      "Creativity trigger": "Not in app",
      "Secondary": "Entertaining"
    },
    {
      "App": "Video streaming app",
      "Features": "Remove a show from the continue watching list",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Video streaming app",
      "Features": "Change the default language",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Netflix",
      "Features": "Preview content through short descriptions",
      "Creativity trigger": "Entertaining",
      "Secondary": ""
    },
    {
      "App": "Video streaming app",
      "Features": "Skip video forward for a set amount of seconds",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Video streaming app",
      "Features": "Fill in a personal recommendation questionnaire",
      "Creativity trigger": "Not in app",
      "Secondary": "Entertaining"
    },
    {
      "App": "Video streaming app",
      "Features": "Preview content through short descriptions",
      "Creativity trigger": "Not in app",
      "Secondary": "Entertaining"
    },
    {
      "App": "Video streaming app",
      "Features": "Set a timer for a continuous watching period",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Netflix",
      "Features": "View a large amount of shows",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Video streaming app",
      "Features": "Shuffle content to view a random show or movie",
      "Creativity trigger": "Not in app",
      "Secondary": "Entertaining"
    },
    {
      "App": "Netflix",
      "Features": "Use the application on tablets and phones",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Video streaming app",
      "Features": "Show an alert when saved shows have new content available",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "YouTube",
      "Features": "Use the application when necessary",
      "Creativity trigger": "Durable",
      "Secondary": ""
    },
    {
      "App": "YouTube",
      "Features": "Sort the watch later playlist",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "YouTube",
      "Features": "Rewind or fast forward by double tapping on either side of the video player",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Video streaming app",
      "Features": "Filter out clips of movies to prevent spoilers",
      "Creativity trigger": "Not in app",
      "Secondary": "Durable"
    },
    {
      "App": "Video streaming app",
      "Features": "Go back to previously watched video",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Instant messaging app",
      "Features": "Remove contacts from recently contacted list while sharing media",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Instant messaging app",
      "Features": "Mute group chat notifications indefinitely",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Instant messaging app",
      "Features": "Revoke admin rights in a group chat",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Instant messaging app",
      "Features": "Draw a message",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "WhatsApp",
      "Features": "Back up conversations to the cloud",
      "Creativity trigger": "Durable",
      "Secondary": ""
    },
    {
      "App": "Instant messaging app",
      "Features": "Set individual chat wallpapers",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "WhatsApp",
      "Features": "Use the application without blocking other communication options",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Instant messaging app",
      "Features": "Fast forward or rewind voice messages",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "WhatsApp",
      "Features": "Make payments to contacts",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Instant messaging app",
      "Features": "Lock the app with a password",
      "Creativity trigger": "Not in app",
      "Secondary": "Durable"
    },
    {
      "App": "Instant messaging app",
      "Features": "Disable the camera sound in settings",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "WhatsApp",
      "Features": "See when texts are delivered and read",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Replay voice messages",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Create stories to share with contacts",
      "Creativity trigger": "Entertaining",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Text, call and send pictures for free",
      "Creativity trigger": "Economical",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Send a large amount of pictures at once",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Interact with a simple and intuitive user interface",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Encrypt messages",
      "Creativity trigger": "Durable",
      "Secondary": ""
    },
    {
      "App": "Instant messaging app",
      "Features": "Use the application on iPad",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Instant messaging app",
      "Features": "Follow other people and keep up with their status updates",
      "Creativity trigger": "Not in app",
      "Secondary": "Entertaining"
    },
    {
      "App": "Instant messaging app",
      "Features": "Separate group chats in a dedicated tab",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "WhatsApp",
      "Features": "Send video messages",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Send stickers",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Create group video chats",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Make calls",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Make video calls",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Instant messaging app",
      "Features": "Deliver messages at a scheduled time",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "WhatsApp",
      "Features": "Send texts",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Make calls",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Make video calls",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Instant messaging app",
      "Features": "Open message threads on the phone's lock screen and reply to them",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "WhatsApp",
      "Features": "Use cellular data to bypass network fees",
      "Creativity trigger": "Economical",
      "Secondary": ""
    },
    {
      "App": "WhatsApp",
      "Features": "Send voice messages",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Instant messaging app",
      "Features": "Create events and invite people in a group chat",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Instant messaging app",
      "Features": "Link account to email so it can be retrieved when you number changes",
      "Creativity trigger": "Not in app",
      "Secondary": "Durable"
    },
    {
      "App": "Instant messaging app",
      "Features": "Create subgroups from group chat",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Image sharing app",
      "Features": "Setup the app to improve accessibility for the elderly",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "SnapChat",
      "Features": "Share photos and videos with friends",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Use filters to change photos and videos",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Put text on photos and videos",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Add stickers to photos and videos",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Save messages to be read later",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Interact with a easy to use and appealing user interface",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Image sharing app",
      "Features": "See history of viewed posts",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "SnapChat",
      "Features": "Videocall and text people using WiFi",
      "Creativity trigger": "Economical",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Block people",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Image sharing app",
      "Features": "Remove people from a group chat",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Image sharing app",
      "Features": "Block all communications when blocking someone",
      "Creativity trigger": "Not in app",
      "Secondary": "Durable"
    },
    {
      "App": "SnapChat",
      "Features": "See saved memories",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Image sharing app",
      "Features": "Mute notifications from lock screen",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "SnapChat",
      "Features": "Create personal filters",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Save images",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Call other people",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Text other people",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Edit your avatar to your personal preference",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Search filters from local artists",
      "Creativity trigger": "Entertaining",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Make group chat calls",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "SnapChat",
      "Features": "Add a timer to photos and videos for how long they will be seen",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Image sharing app",
      "Features": "Automatically select users you have a streak with when sending a message",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "Facebook Messenger",
      "Features": "Use the application for free",
      "Creativity trigger": "Economical",
      "Secondary": ""
    },
    {
      "App": "Facebook Messenger",
      "Features": "Show logged in devices information",
      "Creativity trigger": "Durable",
      "Secondary": ""
    },
    {
      "App": "Facebook Messenger",
      "Features": "Use colorful messages",
      "Creativity trigger": "Entertaining",
      "Secondary": ""
    },
    {
      "App": "Facebook Messenger",
      "Features": "Message Facebook friends",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Facebook Messenger",
      "Features": "Customize individual chats",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Facebook Messenger",
      "Features": "Add custom sound effects",
      "Creativity trigger": "Entertaining",
      "Secondary": ""
    },
    {
      "App": "Facebook Messenger",
      "Features": "Message people without sharing your phone number",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Facebook Messenger",
      "Features": "Video call with good quality",
      "Creativity trigger": "Entertaining",
      "Secondary": ""
    },
    {
      "App": "Instant messaging app",
      "Features": "Unsend a message",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Instant messaging app",
      "Features": "Choose the notification sound",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Instant messaging app",
      "Features": "Add a background picture",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Facebook Messenger",
      "Features": "Attach articles and pictures",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Facebook Messenger",
      "Features": "Use the application quickly and reliably",
      "Creativity trigger": "Durable",
      "Secondary": ""
    },
    {
      "App": "Uber",
      "Features": "See information about ride costs and arrival time",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Uber",
      "Features": "See driver name, vehicle and previous ratings",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Uber",
      "Features": "See driver live location",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Uber",
      "Features": "Call driver without sharing phone number",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Uber",
      "Features": "Schedule a pickup beforehand on a specific time or date",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Uber",
      "Features": "Use the application with a straightforward and easy to use user interface",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Ride sharing app",
      "Features": "Be sure of a ride due to the inability to cancel a ride last minute when close to the pickup location",
      "Creativity trigger": "Not in app",
      "Secondary": "Durable"
    },
    {
      "App": "Ride sharing app",
      "Features": "Choose who your driver will be",
      "Creativity trigger": "Not in app",
      "Secondary": "Durable"
    },
    {
      "App": "Ride sharing app",
      "Features": "Change distance to kilometer or miles regardless of current location",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Ride sharing app",
      "Features": "Change currency to preference regardless of current location",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Ride sharing app",
      "Features": "Indicate to a driver when a you are in a rush",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Gmail",
      "Features": "Sort your inbox",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Send emails",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Receive emails",
      "Creativity trigger": "Complete",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Undo any action",
      "Creativity trigger": "Durable",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Archive emails without deleting them",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Switch between conversation thread and invidual emails",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Mark emails as unread",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Mail app",
      "Features": "Bundle emails by type automatically",
      "Creativity trigger": "Not in app",
      "Secondary": "Light"
    },
    {
      "App": "Gmail",
      "Features": "Use a streamlined and efficient user interface",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Switch between accounts easily",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Delete email from lock screen",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Mail app",
      "Features": "Mute all notifications except from certain people",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Gmail",
      "Features": "Use an application that is fast and reliable",
      "Creativity trigger": "Durable",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "View all unread mail through a unified inbox with multiple accounts",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Snooze messages",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Mail app",
      "Features": "Integrate dropbox for file attachments",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    },
    {
      "App": "Gmail",
      "Features": "Disable conversation view in emails",
      "Creativity trigger": "Adaptable",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Swipe to delete or achrive message",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Use a colorful user interface",
      "Creativity trigger": "Entertaining",
      "Secondary": ""
    },
    {
      "App": "Mail app",
      "Features": "Configure preview shown on lock screen",
      "Creativity trigger": "Not in app",
      "Secondary": "Adaptable"
    },
    {
      "App": "Gmail",
      "Features": "Use the application with quick loading times",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Gmail",
      "Features": "Automatically empty spam and trash folders after 30 days",
      "Creativity trigger": "Light",
      "Secondary": ""
    },
    {
      "App": "Mail app",
      "Features": "Select all email through a button",
      "Creativity trigger": "Not in app",
      "Secondary": "Complete"
    }
  ]

const logos: { [appName: string]: string } = {
    "Spotify": "https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/5a/5e/e7/5a5ee757-5b40-8f2a-96f1-32128c717870/AppIcon-0-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/246x0w.jpg",
    "Instagram": "https://is5-ssl.mzstatic.com/image/thumb/Purple113/v4/c5/eb/bc/c5ebbc97-a0b9-a88c-53f0-6aadab27bc8d/Prod-0-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/690x0w.jpg",
    "Netflix": "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/ef/1d/04/ef1d04d6-3e48-2bc2-5ff6-dc17b264877b/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/690x0w.jpg",
    "YouTube": "https://is2-ssl.mzstatic.com/image/thumb/Purple123/v4/7e/ef/65/7eef65b6-9489-ceb8-162d-21a270f36f4c/AppIcon-0-1x_U007emarketing-0-0-GLES2_U002c0-512MB-sRGB-0-0-0-85-220-0-0-0-7.png/690x0w.jpg",
    "WhatsApp": "https://is5-ssl.mzstatic.com/image/thumb/Purple123/v4/ae/cb/bb/aecbbb90-d987-f217-60ad-8546016579c3/AppIcon-0-1x_U007emarketing-0-0-85-220-6.png/690x0w.jpg",
    "SnapChat": "https://is3-ssl.mzstatic.com/image/thumb/Purple113/v4/be/f1/97/bef1970a-4927-99f4-f3f6-833370e0450a/AppIcon-0-1x_U007emarketing-0-0-GLES2_U002c0-512MB-sRGB-0-0-0-85-220-0-0-0-5.png/690x0w.jpg",
    "Facebook Messenger": "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/f8/40/1f/f8401fb8-e3a4-95f9-f858-15657f714b32/Icon-0-1x_U007emarketing-0-0-85-220-0-7.png/690x0w.jpg",
    "Uber": "https://is3-ssl.mzstatic.com/image/thumb/Purple113/v4/1b/0b/47/1b0b479a-3f04-e4b7-1e64-98b43e438ace/AppIcon-0-1x_U007emarketing-0-0-GLES2_U002c0-512MB-sRGB-0-0-0-85-220-0-0-0-7.png/690x0w.jpg",
    "Gmail": "https://is5-ssl.mzstatic.com/image/thumb/Purple113/v4/41/cc/69/41cc69d6-2fd2-fcb5-c4c0-b7608bf9af4a/logo_gmail_color-0-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/690x0w.jpg"
}

export default class FeatureData {

    public static getData = (trigger: string) => {
        let primaryTriggers: FeatureData[] = [];
        let secondaryTriggers: FeatureData[] = [];

        data.forEach(element => {
            if (element["Creativity trigger"] === trigger) {
                primaryTriggers.push(new FeatureData(element.App, element.Features, element["Creativity trigger"]))
            } else if (element.Secondary === trigger) {
                secondaryTriggers.push(new FeatureData(element.App, element.Features, element.Secondary, true))
            }
        });

        return { primaryTriggers, secondaryTriggers };
    }

    public readonly appName: string;
    public readonly feature: string;
    public readonly trigger: string;
    private readonly isSecondary: boolean;

    private constructor(appName: string, feature: string, trigger: string, isSecondary: boolean = false) {
        this.appName = appName;
        this.feature = feature;
        this.trigger = trigger;
        this.isSecondary = isSecondary;
    }

    public getExampleDescription(): string {
        if (!this.isSecondary) {
            return "How about " + this.appName + " which allows you to " + this.feature.toLowerCase()
        } else {
            return "A " + this.appName.toLowerCase() + " that allows you to " + this.feature.toLowerCase()
        }
    }

    public getLogoUrl(): string {
        return logos[this.appName];
    }
}