export default class CardData {

    public static fromName(name: string): CardData {
        return CardData.Values.find(e => e.title === name)!;
    }

    public static readonly ENTERTAINING = new CardData("Entertaining", "Extend your solution with a feature that makes it fun or captivating", "... add an unsual feature that no competitor's solution has", "... find a feature for your solution that makes it witty and engaging to use");
    public static readonly LIGHT = new CardData("Light", "Try to simplify your solution, to make its structure lighter, more lightweight", "... remove parts of the solution to make it less busy or time consuming", "... revise your solution to make it look thinner or smaller");
    public static readonly ADAPTABLE = new CardData("Adaptable", "Can you replace multiple products with one adaptable product?", "... add a feature to your solution to make it able to change", "... try to make your solution more malleable, more flexible for the user");
    public static readonly COMPLETE = new CardData("Complete", "Make your solution more integrated, more comprehensive", "... improve your solution to make it more global, doing more things", "... add a feature that makes your solution more comfortable to use in different contexts");

    public static readonly Values = [CardData.ENTERTAINING, CardData.LIGHT, CardData.ADAPTABLE, CardData.COMPLETE]

    public readonly title: string;
    public readonly description: string;
    public readonly guideline1: string;
    public readonly guideline2: string;
    
    private constructor(title: string, description: string, guideline1: string, guideline2: string) {
        this.title = title;
        this.description = description;
        this.guideline1 = guideline1;
        this.guideline2 = guideline2;
    }
}